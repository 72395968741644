/* eslint-disable camelcase */
import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../../../components/layout';
import Section from '../../../components/section';

export const query = graphql`
  query QueryProductTitle($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/products/" }) {
      frontmatter {
        title
        tagline
        logo {
          publicURL
        }
      }
    }
  }
`;

const ProductTitlePage = ({ data }) => {
  if (!data.mdx) {
    // for blog & policies
    return null;
  }

  const { frontmatter } = data.mdx;
  const { title, tagline, logo } = frontmatter;

  return (
    <>
      <GatsbySeo noindex />
      <Layout pageTitle={title} showNavigation={false}>
        <Section className="bg-yellow-100 bg-gradient-to-br from-yellow-100 via-red-100 to-pink-100 dark:from-yellow-600 dark:via-red-600 dark:to-pink-600">
          <section className="text-gray-600 body-font">
            <div className="container flex flex-col items-center md:flex-row">
              <div className="flex flex-col items-start my-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:text-left">
                <div>
                  {logo && logo.publicURL ? (
                    <img
                      src={logo.publicURL}
                      alt={title}
                      style={{ maxWidth: 80 }}
                    />
                  ) : null}
                </div>
                <h1 className="my-10 text-2xl font-bold text-gray-900 dark:text-gray-100 sm:text-4xl title-font">
                  {title}
                </h1>
                <h2 className="leading-relaxed dark:text-gray-200">
                  {tagline}
                </h2>
              </div>
            </div>
          </section>
        </Section>
      </Layout>
    </>
  );
};

export default ProductTitlePage;
